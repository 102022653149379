<template>
    <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        :label-width="labelWidth"
        class="sc-form"
        size="small"
    >
        <el-row :gutter="16">
            <el-col 
                v-for="(item, index) in visibleColumns" 
                :key="index"
                :span="colSpan"
            >
                <el-form-item 
                    :label="item.label"
                    :prop="item.prop"
                >
                    <!-- 自定义字段插槽 -->
                    <template v-if="$slots[item.prop]">
                        <slot 
                            :name="item.prop"
                            :row="formData"
                            :col="item"
                        />
                    </template>
                    <template v-else>
                        <!-- 输入框 -->
                        <el-input 
                            v-if="!item.type || item.type === 'input'"
                            v-model="formData[item.prop]"
                            :placeholder="'请输入' + item.label"
                            size="small"
                        />
                        <!-- 文本域 -->
                        <el-input
                            v-if="item.type === 'textarea'"
                            v-model="formData[item.prop]"
                            type="textarea"
                            :rows="item.minRows || 2"
                            :placeholder="'请输入' + item.label"
                            size="small"
                        />
                        <!-- 上传组件 -->
                        <el-upload
                            v-if="item.type === 'upload'"
                            v-model:file-list="uploadFileLists[item.prop]"
                            :action="'/api' + item.action"
                            :list-type="item.listType || 'picture'"
                            :on-success="(response) => handleUploadSuccess(response, item)"
                            :on-error="handleUploadError"
                            :before-upload="handleBeforeUpload"
                            :headers="uploadHeaders"
                            :limit="item.limit || 1"
                            :auto-upload="false"
                            :ref="(el) => setUploadRef(el, item.prop)"
                        >
                            <el-button type="primary" size="small">点击上传</el-button>
                            <template #tip>
                                <div class="el-upload__tip" style="font-size: 11px;">
                                    只能上传jpg/png文件
                                </div>
                            </template>
                        </el-upload>
                        <!-- 数字输入框 -->
                        <el-input-number
                            v-if="item.type === 'number'"
                            v-model="formData[item.prop]"
                            :min="item.min ?? 0"
                            :max="item.max"
                            :placeholder="'请输入' + item.label"
                            :controls-position="item.controlsPosition || 'right'"
                            size="small"
                        />
                        
                        <!-- 选择器 -->
                        <el-select
                            v-if="item.type === 'select' || item.dicUrl"
                            v-model="formData[item.prop]"
                            :placeholder="'请选择' + item.label"
                            :multiple="item.multiple"
                            :clearable="true"
                            size="small"
                        >
                            <el-option
                                v-if="item.dicUrl"
                                v-for="dict in dictCache[item.dicUrl]"
                                :key="dict.dictKey"
                                :label="dict.dictValue"
                                :value="dict.dictKey"
                            />
                            <el-option
                                v-else
                                v-for="dict in item.dicData"
                                :key="dict.value"
                                :label="dict.label"
                                :value="dict.value"
                            />
                        </el-select>

                        <!-- 日期选择器 -->
                        <el-date-picker
                            v-if="item.type === 'date'"
                            v-model="formData[item.prop]"
                            type="date"
                            :placeholder="'请选择' + item.label"
                            size="small"
                        />
                    </template>
                </el-form-item>
            </el-col>
        </el-row>

        <el-form-item class="form-footer">
            <slot name="footer">
                <div class="footer-buttons">
                    <el-button 
                        type="primary" 
                        :loading="loading" 
                        @click="debouncedSubmit"
                        size="small"
                    >保存</el-button>
                    <el-button 
                        @click="$emit('cancel')"
                        size="small"
                    >取消</el-button>
                </div>
            </slot>
        </el-form-item>
    </el-form>
</template>

<script>
import axios from '@/axios'
import { getToken } from '@/utils/auth'

export default {
    name: 'ScForm',
    props: {
        option: {
            type: Object,
            required: true
        },
        modelValue: {
            type: Object,
            default: () => ({})
        },
        labelWidth: {
            type: String,
            default: '120px'
        },
        colSpan: {
            type: Number,
            default: 24
        }
    },
    emits: ['update:modelValue', 'submit', 'cancel'],
    data() {
        return {
            formData: {},
            rules: {},
            loading: false,
            submitTimer: null,
            dictCache: {},
            updateTimer: null,
            uploadRefs: {},
            uploadFileLists: {},
            uploadHeaders: {
                'Sc-Auth': 'bearer ' + getToken()
            }
        }
    },
    watch: {
        modelValue: {
            handler(val) {
                if (JSON.stringify(val) === JSON.stringify(this.formData)) {
                    return;
                }
                
                if (this.updateTimer) {
                    clearTimeout(this.updateTimer);
                }
                
                this.updateTimer = setTimeout(() => {
                    const processedData = { ...val }
                    this.visibleColumns.forEach(item => {
                        if (item.type === 'number' && (processedData[item.prop] === undefined || processedData[item.prop] === null)) {
                            processedData[item.prop] = 0
                        }
                    })
                    this.formData = processedData
                }, 0);
            },
            immediate: true,
            deep: true
        },
        formData: {
            handler(val) {
                if (JSON.stringify(val) === JSON.stringify(this.modelValue)) {
                    return;
                }
                
                if (this.updateTimer) {
                    clearTimeout(this.updateTimer);
                }
                
                this.updateTimer = setTimeout(() => {
                    this.$emit('update:modelValue', val);
                }, 0);
            },
            deep: true
        }
    },
    created() {
        this.initRules()
        this.loadDictionaryData()
    },
    computed: {
        visibleColumns() {
            return this.option.column.filter(item => item.addDisplay !== false);
        }
    },
    methods: {
        initRules() {
            this.rules = this.visibleColumns.reduce((acc, item) => {
                if (item.required) {
                    acc[item.prop] = [{
                        required: true,
                        message: `请${item.type === 'select' ? '选择' : '输入'}${item.label}`,
                        trigger: item.type === 'select' ? 'change' : 'blur'
                    }]
                }
                return acc
            }, {})
        },
        debouncedSubmit() {
            if (this.submitTimer) {
                clearTimeout(this.submitTimer)
            }
            
            this.submitTimer = setTimeout(() => {
                this.submitFormWithLoading()
            }, 300) // 300ms 的防抖延迟
        },

        async submitFormWithLoading() {
            if (this.loading) return
            
            try {
                await this.$refs.form.validate()
                
                this.loading = true
                
                try {
                    // 先处理所有文件上传
                    await this.submitAllUploads();
                    
                    // 创建done回调函数
                    const done = () => {
                        this.loading = false;
                    };
                    
                    // 创建loading回调函数
                    const loading = () => {
                        this.loading = true;
                    };
                    
                    // 再提交表单数据，并传递回调函数
                    await this.$emit('submit', this.formData, done, loading)
                } catch (error) {
                    console.error(error)
                    throw error
                }
            } catch (error) {
                // 验证失败时不做处理
                this.loading = false;
            } finally {
                // 不在这里设置loading = false，而是由done回调函数来控制
                // 只有在表单验证失败时，loading已经在catch块中设置为false
            }
        },

        resetForm() {
            this.$refs.form.resetFields()
        },

        // 组件销毁时清除定时器
        beforeDestroy() {
            if (this.updateTimer) {
                clearTimeout(this.updateTimer);
            }
            if (this.submitTimer) {
                clearTimeout(this.submitTimer);
            }
        },

        async loadDictionaryData() {
            for (const column of this.visibleColumns) {
                if (column.dicUrl && !this.dictCache[column.dicUrl]) {
                    try {
                        const response = await axios.get(column.dicUrl)
                        if (response.data.success) {
                            this.dictCache[column.dicUrl] = response.data.data
                        }
                    } catch (error) {
                        console.error('Failed to load dictionary data:', error)
                    }
                }
            }
        },

        setUploadRef(el, prop) {
            if (el) {
                this.uploadRefs[prop] = el;
            }
        },

        async submitAllUploads() {
            const uploadPromises = [];
            
            for (const [prop, upload] of Object.entries(this.uploadRefs)) {
                if (upload && this.uploadFileLists[prop]?.length > 0) {
                    const uploadPromise = new Promise((resolve, reject) => {
                        const originalOnSuccess = this.handleUploadSuccess;
                        const originalOnError = this.handleUploadError;
                        
                        // 临时替换成功回调
                        this.handleUploadSuccess = (response, item) => {
                            originalOnSuccess(response, item);
                            resolve(response);
                        };
                        
                        // 临时替换错误回调
                        this.handleUploadError = (error) => {
                            originalOnError(error);
                            reject(error);
                        };
                        
                        upload.submit();
                    });
                    
                    uploadPromises.push(uploadPromise);
                }
            }
            
            if (uploadPromises.length > 0) {
                await Promise.all(uploadPromises);
            }
        },

        handleUploadSuccess(response, item) {
            if (response.success) {
                const fileUrl = typeof response.data === 'string' ? response.data : response.data.link;
                this.formData[item.prop] = fileUrl;
                this.$message.success('上传成功');
            } else {
                this.$message.error(response.msg || '上传失败');
            }
        },

        handleUploadError(error) {
            console.error('上传失败:', error)
            this.$message.error('上传失败，请重试')
        },

        handleBeforeUpload(file) {
            // 验证文件类型
            const isValidType = ['image/jpeg', 'image/png'].includes(file.type)
            if (!isValidType) {
                this.$message.error('只能上传 JPG/PNG 格式的图片！')
                return false
            }

            // 验证文件大小（默认限制为 2MB）
            const isLt2M = file.size / 1024 / 1024 < 2
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 2MB！')
                return false
            }

            return true
        }
    }
}
</script>
<style scoped>
.sc-form {
    padding: 16px;
    max-height: calc(100vh - 160px);
    overflow-y: auto;
    position: relative;
}

.form-footer {
    text-align: center;
    margin-top: 16px;
    position: sticky;
    bottom: 0;
    background-color: #fff;
    padding: 8px 0;
    margin-bottom: 0;
}

.footer-buttons {
    display: flex;
    justify-content: center;
    gap: 8px;
}

.sc-form::-webkit-scrollbar {
    width: 5px;
}

.sc-form::-webkit-scrollbar-thumb {
    background-color: #dcdfe6;
    border-radius: 2px;
}

.sc-form::-webkit-scrollbar-track {
    background-color: #f5f7fa;
}

:deep(.el-form-item) {
    margin-bottom: 14px;
}


:deep(.el-form-item.form-footer) {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

:deep(.el-form-item.form-footer .el-form-item__content) {
  width: 100%;
  margin-left: 0 !important;  /* 覆盖默认的 margin-left */
}

.footer-buttons {
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

:deep(.el-form-item__label) {
  font-size: 11px;
  line-height: 28px;
}

:deep(.el-input__inner),
:deep(.el-textarea__inner),
:deep(.el-select__input),
:deep(.el-input-number__decrease),
:deep(.el-input-number__increase),
:deep(.el-input-number__input),
:deep(.el-select-dropdown__item),
:deep(.el-button) {
  font-size: 11px;
  height: 28px;
  line-height: 28px;
}

:deep(.el-textarea__inner) {
  line-height: 1.5;
}

:deep(.el-form--small .el-form-item__label) {
  line-height: 28px;
}

:deep(.el-select-dropdown__item) {
  height: 28px;
  line-height: 28px;
}

:deep(.el-button) {
  padding: 6px 12px;
}
</style>
